<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="O2CFlow Report">
          <div class="tab-text">
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Operating Unit/Territory</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedTerritories"
                  :options="optionTerritories"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Distribution Channel</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedDistributionChannels"
                  :options="optionDistributionChannels"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedSales"
                  :options="optionSales"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>SO Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex items-center gap-4">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="soDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button color="danger" @click="clearSODate()"
                  >X</vs-button
                >
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex items-center gap-4">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="docPostingDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button color="danger" @click="clearPostingDate()"
                  >X</vs-button
                >
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button color="success" @click="handleDrawTable()"
                  >Get Data</vs-button
                >
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <data-table
                  :baseUrl="this.baseUrl"
                  :draw="this.draw"
                  :territoryIDs="this.territoryIDs"
                  :distributionChannelIDs="this.distributionChannelIDs"
                  :saleIDs="this.saleIDs"
                  :startSODate="this.soDate.startDate"
                  :endSODate="this.soDate.endDate"
                  :startPostingDate="this.docPostingDate.startDate"
                  :endPostingDate="this.docPostingDate.endDate"
                ></data-table>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DataTable from './DataTable.vue';
import DataTableExport from './DataTableExport.vue';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    DataTable,
    DataTableExport,
    DateRangePicker,
    Datepicker,
  },
  data() {
    return {
      baseUrl: '/api/report/v1/report/inbound',
      selectedTerritories: [],
      optionTerritories: [],
      selectedDistributionChannels: [],
      optionDistributionChannels: [],
      selectedSales: [],
      optionSales: [],
      territoryIDs: [],
      territoryCodes: [],
      territoryNames: [],
      distributionChannelIDs: [],
      distributionChannelCodes: [],
      distributionChannelNames: [],
      saleIDs: [],
      saleCodes: [],
      saleNames: [],
      draw: 0,
      dateRange: '',
      docPostingDate: {
        startDate: null,
        endDate: null,
      },
      soDate: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    handleClose() {
      this.detail = false;
    },

    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format('dddd, MMMM Do YYYY');
      }
      return a;
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/ou').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
    getDistributionChannels() {
      this.$http.get('/api/report/v1/master/distribution-channel').then((resp) => {
        this.optionDistributionChannels = resp.data.records;
      });
    },
    getSales() {
      this.$http.get('/api/report/v1/master/sales').then((resp) => {
        this.optionSales = resp.data.records;
      });
    },
    handleDrawTable() {
      this.draw++;
    },
    clearPostingDate() {
      this.docPostingDate = {
        startDate: null,
        endDate: null,
      };
    },
    clearSODate() {
      this.soDate = {
        startDate: null,
        endDate: null,
      };
    },
  },
  mounted() {
    this.getTerritories();
    this.getDistributionChannels();
    this.getSales();
  },
  watch: {
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },
    selectedDistributionChannels(val) {
      let distributionChannelIDs = [];
      let distributionChannelCodes = [];
      let distributionChannelNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          distributionChannelIDs.push(e.id);
          distributionChannelCodes.push(e.code);
          distributionChannelNames.push(e.name);
        }
      });

      this.distributionChannelIDs = distributionChannelIDs;
      this.distributionChannelCodes = distributionChannelCodes;
      this.distributionChannelNames = distributionChannelNames;
    },
    selectedSales(val) {
      let saleIDs = [];
      let saleCodes = [];
      let saleNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          saleIDs.push(e.id);
          saleCodes.push(e.code);
          saleNames.push(e.name);
        }
      });

      this.saleIDs = saleIDs;
      this.saleCodes = saleCodes;
      this.saleNames = saleNames;
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
